<template>
  <div id="withdrawal_record">
    <p class="all-withdrawal">累计提现:<span>{{cumulative_withdrawal}}</span>元</p>
    <van-list loading-text="加载中..." v-model="loading" offset="50" :finished="finished" finished-text="没有更多了"
              @load="onLoad">
      <div class="table-withdrawal" v-for="(item) in withdrawalLogs.rows" v-bind:key="item.id">
        <div class="table-withdrawal-top">
          <p class="id">提现ID：<span>{{item.account_id}}</span></p>
          <p class="status" v-if="item.status===1">提现失败</p>
          <p class="status success" v-else-if="item.status===2">提现成功</p>
          <p class="status apply" v-else-if="item.status===0">提现审核中</p>
        </div>
        <div class="table-withdrawal-bottom">
          <div class="record">
            <p class="money">提现金额：￥{{item.extracted.toFixed(2)/100}} <span class="back" v-if="item.status===1">(已退回)</span></p>
            <p class="time">提现时间：{{formatDate(item.updated_at)}}</p>
          </div>
          <span class="customer" v-on:click="customer()">联系客服</span>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import "@/styles/Withdrawal_record.scss";
export default {
  name: "Withdrawal_record",
  data(){
    return{
      withdrawalLogs:{rows: [], page: 0},
      cumulative_withdrawal:'',
      loading: false,
      finished: false,
      page:1
    }
  },
  created() {
    this.cumulative_withdrawal=localStorage.getItem('all_withdrawal');
  },
  methods:{
    WithdrawalLogs(page,cb){
      page = page === undefined ? 1 : page;
      let rows = 20;
      this.$request.request(this.$member.token()).get('nuclear/withdraw/log',{page: page, size: rows}).then((response) => {
        if (response.data.data.page > this.withdrawalLogs.page){
          response.data.data.data.forEach((item) => {
            this.withdrawalLogs.rows.push(item)
          })
          this.withdrawalLogs.total = response.data.data.total;
          this.withdrawalLogs.page = response.data.data.page;
          this.finished = Math.ceil(this.withdrawalLogs.total / rows) <= page;
          if (cb !== undefined)
            cb();
        }
      });
    },
    formatDate(time) {
      let date = new Date(time * 1000);
      return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds()
    },
    onLoad() {
      if (!this.loading) {
        this.loading = !this.loading;
        this.WithdrawalLogs(this.page++, () => {
          setTimeout(() => {
            this.loading = false;
          }, 1000)
        })
      }
    },
    customer(){
      window.location.href=('https://api.initap.com/consumer'+'?token='+this.$member.token());
     // this.$router.push({ path: '/invite', query: { url: 'customer' }})
    }
  }
}
</script>

<style scoped>

</style>