<template>
  <div id="list">
    <div class="list-top">
      <div class="list-top-left">
        <img src="/images/listtext.png" alt="">
        <div class="list-time">
          <span  v-bind:class="{list_active:Monthtype==='thisMonth'}" v-on:click="changeMonthClass('thisMonth')">本月</span>
          <span v-bind:class="{list_active:Monthtype==='nextMonth'}" v-on:click="changeMonthClass('nextMonth')">上月</span>
        </div>
      </div>
      <img class="listIcon" src="/images/listicon.png" alt="">
    </div>
    <div class="list-middle">
      <div class="list-middle-top">
        <span  v-bind:class="{list_middle_top_active:type==='earnings'}" v-on:click="changeClass('earnings')">收益排行</span>
        <span v-bind:class="{list_middle_top_active:type==='sole'}" v-on:click="changeClass('sole')">销售排行</span>
        <span v-bind:class="{list_middle_top_active:type==='invite'}" v-on:click="changeClass('invite')">邀请排行</span>
      </div>
      <div class="list-middle-bottom">
        <div class="list-middle-bottom-table" v-bind:class="{second:item.list_active}"  v-for="(item,index) in listLogs.rows" v-bind:key="item.id">
          <div class="table-list">
            <img  src="/images/list1.png" v-if="index===0" alt="">
            <img  src="/images/list2.png" v-else-if="index===1" alt="">
            <img  src="/images/list3.png" v-else-if="index===2" alt="">
            <span v-if="index>2">0{{index+1}}</span>
          </div>
          <div class="table-middle">
            <div><img src="/images/caitou.png" alt=""></div>
            <span>{{item.nickname}}</span>
          </div>
          <p class="table-money" v-if="type==='earnings'||type==='sole'">￥<span>{{item.value.toFixed(2)/100}}</span></p>
          <p class="table-money" v-else-if="type!=='earnings'"><span>{{item.value}}</span></p>
        </div>
      </div>
    </div>
    <div class="list-bottom">
        <div class="list-bottom-left">
          <img src="/images/caitou.png" alt="">
        </div>
        <div class="list-bottom-right">
          <p class="list-bottom-right-username">{{last_nickname}}</p>
          <p class="list-bottom-right-ranking" v-if="type==='earnings'">排名：第<span>100+</span> | 收益：￥<span>{{Number(last_money).toFixed(2)/100}}</span></p>
          <p class="list-bottom-right-ranking" v-else-if="type==='sole'">排名：第<span>100+</span> | 销售：￥<span>{{Number(last_money).toFixed(2)/100}}</span></p>
          <p class="list-bottom-right-ranking" v-else-if="type==='invite'">排名：第<span>100+</span> | 邀请：<span>{{last_money}}</span></p>
        </div>
    </div>
  </div>
</template>

<script>
import "@/styles/list.scss";
export default {
  name: "List",
  created(){
    this.ranking();
  },
  data(){
    return{
      type:this.$router.currentRoute.value.query.type||'earnings',
      Monthtype:this.$router.currentRoute.value.query.Monthtype||'thisMonth',
      listLogs:{rows: [], page: 0},
      last_nickname:'',
      last_money:'',
      list_active:false,
      time:''
    }
  },
  methods:{
    ranking(){
      this.$request.request(this.$member.token()).get('nuclear/ranking',{type:this.type_list,date:this.time}).then((response) => {
        this.last=response.data.data[response.data.data.length-1]
        this.last_nickname=this.last.nickname;
        this.last_money=this.last.value;
        this.listLogs.rows=[];
        if (this.type_list!=""){
          response.data.data.pop();
        }
        response.data.data.forEach((item,index) => {
          if (index%2===1){
            item.list_active=true
          }
          this.listLogs.rows.push(item)
          // if (index>2){
          //   this.list_index=index;
          // }
        })
      });
    },
    changeClass(type){
      this.type=type;
      if (this.type==='sole'){
        this.type_list=1;
      }else if (this.type==='invite'){
        this.type_list=2;
      }else{
        this.type_list="";
      }
      this.listLogs.rows=[];
      this.ranking();
    },
    changeMonthClass(Monthtype){
      this.Monthtype=Monthtype;
      let now = new Date(); // 当前日期
      let nowYear = now.getFullYear(); //当前年
      let nowMonth = now.getMonth()+1; //当前月 （值为0~11）注意此处范围
      if (this.Monthtype==='thisMonth'){
        if (nowMonth<10){
          this.time=nowYear+'-'+'0'+nowMonth;
        }else{
          this.time=nowYear+'-'+nowMonth;
        }
      }else{
       let lastTime= Date.parse(new Date())/1000-new Date().getDate()*24*3600-1;
        let LastYear= new Date(lastTime*1000).getFullYear();
        let LastMonth= new Date(lastTime*1000).getMonth()+1;
        if (LastMonth<10){
          this.time=LastYear+'-'+'0'+LastMonth;
        }else{
          this.time=LastYear+'-'+LastMonth;
        }
      }
      this.ranking();
    }
  }
}
</script>

<style scoped>

</style>