<template>
<!--  <iframe :src="invite" v-show="invite_url"></iframe>-->
<!--  <iframe :src="invite" v-show="customer_url"></iframe>-->
  <iframe :src="invite"></iframe>
</template>

<style scoped lang="scss">
iframe {
  border: 0;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  position: fixed;

  &::-webkit-scrollbar {
    display: none;
  };
}

</style>

<script>
export default {
  name: "Invite",

  data() {
    return {
      // invite_url:true,
      // customer_url:false,
      invite:'https://initap.com/activity/index.html?token=' + this.$member.token(),
      // invite:'',
    }
  },
  created() {
    // let query=this.$route.query.url;
    // if (query==='customer'){
    //   this.invite='https://api.initap.com/consumer'+'?token='+this.$member.token();
    //   console.log(this.invite);
    // }else{
    //   this.invite='http://192.168.50.6:8000/index.html?token=' + this.$member.token();
    // }
  }
}
</script>

<style scoped>

</style>