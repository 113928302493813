<template>
  <div id="withdrawal_success">
    <div class="withdrawal_success-top">
      <img src="/images/withdrawalS.png" alt="">
      <p>申请成功</p>
      <p>提现金额将在3~7个工作日内到达您的账户</p>
    </div>
    <div class="withdrawal_success-middle">
      <p>提现金额：￥{{withdrawal_value}}</p>
      <p>提现渠道：{{withdrawal_channel}}</p>
      <p>提交时间：{{time}}</p>
    </div>
    <div class="withdrawal_success-bottom">
      <router-link to="/Withdrawal">
        <span>完成</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import "@/styles/Withdrawal_success.scss";
export default {
  name: "Withdrawal_success",
  created(){
    this.withdrawal_value=localStorage.getItem('Withdrawal_money');
    this.withdrawal_channel= localStorage.getItem('Withdrawal_channel');
  },
  data(){
    return{
      time:this.formatDate(),
       withdrawal_value:'',
       withdrawal_channel:''
    }
  },
  methods:{
    formatDate() {
      let date = new Date();
      return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds()
    },
  }

}
</script>

<style scoped>

</style>