<template>
  <div id="grade_center">
    <div class="grade_center-top">
      <div class="top-icon">
        <img src="/images/gradeicon.png" alt="">
      </div>
      <div class="top-user">
        <img src="/images/caitou.png" alt="">
        <span>V{{levels}}</span>
      </div>
      <div class="top-level">
        <div class="level">
          <span>V1</span>
          <span>V2</span>
          <span>V3</span>
        </div>
        <div class="progress">
          <span class="span_active"></span>
          <div v-bind:class="{active:active_level_2}"></div>
          <span  v-bind:class="{span_active:active_level_2}"></span>
          <div v-bind:class="{active:active_level_3}"></div>
          <span v-bind:class="{span_active:active_level_3}"></span>
        </div>
        <van-skeleton :row="1" :loading="levelName_load">
          <div class="level-name">
            <span>{{level1_name}}</span>
            <span>{{level2_name}}</span>
            <span>{{level3_name}}</span>
          </div>
        </van-skeleton>
      </div>
    </div>
    <div class="grade_center-bottom">
      <div class="grade_center-bottom-top">
        <van-skeleton :row="1" :loading="isLoad">
        <span>当前等级</span>
          <p>{{level_name}} V{{levels}}</p>
        </van-skeleton>
      </div>
      <div class="grade_center-bottom-bottom">
        <div class="top">
          <p class="current-equity"><span>V{{levels}}</span>等级权益</p>
          <div class="current-proportion">
              <div class="current-proportion-product">
                <div class="grade-ellipse">
                    <span>{{goods_commission_ratio}}%</span>
                </div>
                <p class="grade-p">一级好友佣金比例</p>
              </div>
              <div class="current-proportion-invite">
                <div class="grade-ellipse">
                  <span>{{invitation_commission_ratio}}%</span>
                </div>
                <p class="grade-p">二级好友佣金比例</p>
              </div>
          </div>
        </div>
        <div class="middle" v-show="next_equity">
          <p class="next-equity">下个等级可获得权益</p>
          <div class="next-proportion">
            <div class="next-proportion-product">
              <div class="grade-ellipse">
                <span>{{next_goods_commission_ratio}}%</span>
              </div>
              <p class="grade-p">一级好友佣金比例</p>
            </div>
            <div class="next-proportion-invite">
              <div class="grade-ellipse">
                <span>{{next_invitation_commission_ratio}}%</span>
              </div>
              <p class="grade-p">二级好友佣金比例</p>
            </div>
<!--            <div class="awarding">-->
<!--              <div class="grade-ellipse">-->
<!--                <span>3%</span>-->
<!--              </div>-->
<!--              <p class="grade-p">积分奖励</p>-->
<!--            </div>-->
          </div>
        </div>
<!--        <div class="bottom">-->
<!--          <span v-on:click="invite_friend()">去邀请好友</span>-->
<!--        </div>-->
      </div>
    </div>
  </div>
  <Popout v-show="isPrpout" v-on:confirm="onConfirm" content="当前版本过低,请尽快升级最新版本!"/>
</template>

<script>
import "@/styles/grade_center.scss";
import Popout from './alert.vue'

export default {
  name: "Grade_center",
  components: {
    Popout
  },
  created() {
    this.$member.load((member) => {
      this.isLoad=false
      this.level_name = member.distributor.edges.distributor_mission.name;
      this.levels = member.distributor.edges.distributor_mission.level;
      this.$request.request(this.$member.token()).get('nuclear/mission').then((response) => {
        this.levelName_load=false;
        this.level1_name=response.data.data[0].name;
        this.level2_name=response.data.data[1].name;
        this.level3_name=response.data.data[2].name;
        if (this.levels<2){
          this.goods_commission_ratio = response.data.data[0].goods_commission_ratio/this.calculation_base*100;
          this.invitation_commission_ratio = response.data.data[0].invitation_commission_ratio/this.calculation_base*100;
          this.next_goods_commission_ratio = response.data.data[1].goods_commission_ratio/this.calculation_base*100;
          this.next_invitation_commission_ratio = response.data.data[1].invitation_commission_ratio/this.calculation_base*100;
        }else if (this.levels===2){
          this.active_level_2=true;
          this.goods_commission_ratio = response.data.data[1].goods_commission_ratio/this.calculation_base*100;
          this.invitation_commission_ratio = response.data.data[1].invitation_commission_ratio/this.calculation_base*100;
          this.next_goods_commission_ratio = response.data.data[2].goods_commission_ratio/this.calculation_base*100;
          this.next_invitation_commission_ratio = response.data.data[2].invitation_commission_ratio/this.calculation_base*100;
        }else{
          this.active_level_2=true;
          this.active_level_3=true;
          this.goods_commission_ratio = response.data.data[2].goods_commission_ratio/this.calculation_base*100;
          this.invitation_commission_ratio = response.data.data[2].invitation_commission_ratio/this.calculation_base*100;
          this.next_equity=false;
        }
      });
    });
    this.$request.request().get('nuclear/settlement_setting').then((response) => {
      this.calculation_base = response.data.data.calculation_base;
    });
    if (this.$member.token()===''){
      this.onClick();
    }
  },
  data(){
    return{
      level_name:'',
      levels:'',
      goods_commission_ratio:'-',
      invitation_commission_ratio:'-',
      next_goods_commission_ratio:'-',
      next_invitation_commission_ratio:'-',
      active_level_2:false,
      active_level_3:false,
      next_equity:true,
      level1_name:'',
      level2_name:'',
      level3_name:'',
      isPrpout:false,
      isLoad:true,
      levelName_load:true
    }
  },
methods:{
  invite_friend(){
    this.$router.push("invite")
  },
  onClick () {
    this.isPrpout = true
  },

  // 点击确定隐藏
  onConfirm (val) {
    console.log(val)
    this.isPrpout = false
  },
}
}
</script>

<style scoped>

</style>