<template>
  <div class="popout">    <!-- 弹框组件 -->
    <div class="popout_box">
      <div class="popout_box_top">
        <p class="title">温馨提示</p>
        <p class="content">{{content}}</p>
      </div>
      <div class="popout_box_bot">
        <span v-on:click="onConfirm">确定</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isPrpout: true
    }
  },
  props: ['content'],
  methods: {
    //  点击确定
    onConfirm () {
      this.$emit('confirm')
    },
  }
}
</script>

<style scoped>
.popout{
  width: 100vw;height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popout_box{
  width: 80%;height: 30%;
  background-color: #181818;
  border-radius: 10px;
}
.popout_box_top{
  width: 100%;height: 65%;
}
.popout_box_top .title{
  text-align: center;
  font-size: 1.2rem;
  margin-top: 10px;
  color: #FFFFFF;
}
.popout_box_top .content{
  font-size: 1rem;
  font-weight: 500;
  color: #FFFFFF;
  width: 90%;
  margin: 25px auto 0;
  text-align: center;
}
.popout_box_bot{
  width: 100%;height: 25%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.popout_box_bot span{
  width: 90%;
  height: 75%;
  background-color: #EBCA7D;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
</style>
