<template>
  <div id="invite">
    <div class="invite-top">
      <span v-bind:class="{active:type==='first'}" v-on:click="changeInvite('first')">一级好友</span>
      <span v-bind:class="{active:type==='second'}" v-on:click="changeInvite('second')">二级好友</span>
    </div>
    <div class="invite-middle">
      <div class="invite-middle-left">
        <p>共<span>{{invite_value}}</span>个邀请单</p>
        <p>累积获得佣金奖励￥<span>{{Number(invite_money_all).toFixed(2)/100}}</span>（含待结算￥<span>{{Number(invite_money_wait).toFixed(2)/100}}</span>）</p>
      </div>
      <div class="invite-middle-right">
        <span v-on:click="mask()">{{invite_time_select}}</span>
        <div class="invite-timeToogle" v-show="timeToogle">
          <span v-on:click="maskRemove('all_time')">全部时间</span>
          <span v-on:click="maskRemove('seven')">最近7天</span>
          <span v-on:click="maskRemove('during_month')">本月</span>
        </div>
      </div>
    </div>
    <van-list loading-text="加载中..." v-model="loading" offset="50" :finished="finished" finished-text="没有更多了"
              @load="onLoad">
      <div class="invite-bottom" v-for="(item) in inviteLogs.rows" v-bind:key="item.id" >
        <div class="invite-bottom-left">
          <img src="/images/caitou.png" alt="">
        </div>
        <div class="invite-bottom-middle">
          <p class="username">{{item.nickname}}<span>ID:{{item.ident}}</span></p>
          <p class="money">我的佣金奖励：￥<span>{{item.income.toFixed(2)/100}}</span></p>
          <p class="time" v-show="first_invite"><span>{{formatDate(item.bind_at)}}</span> 邀请</p>
          <p class="friend" v-show="second_invite">来自好友 <span>{{item.from}}</span> 邀请</p>
        </div>
        <!--      <div class="invite-bottom-right"><span>></span></div>-->
      </div>
    </van-list>
    <div v-show="showDialog"  class="mask" v-on:click="mask_close()">

    </div>
  </div>
</template>

<script>
import "@/styles/invites.scss";
export default {
  name: "Invites",
  data(){
    return{
      type: this.$router.currentRoute.value.query.type||'first',
      time_type:this.$router.currentRoute.value.query.type||'all_time',
      timeToogle:false,
      showDialog:false,
      second_invite:false,
      first_invite:true,
      inviteLogs: {rows: [], page: 0},
      invite_value:'',
      invite_money_all:'',
      invite_money_wait:'',
      invite_time_select:'全部时间',
      loading:false,
      finished:false,
      page:1
    }
  },
  created() {
  },
  methods:{
    InviteLogs(page,cb){
      page = page === undefined ? 1 : page;
      let rows = 20;
      this.$request.request(this.$member.token()).get('nuclear/invites',{page: page, size: rows,friend_type:this.friend_type}).then((response) => {
        this.invite_value= response.data.data.total;
        this.invite_money_all=response.data.data.commission.total;
        this.invite_money_wait=response.data.data.commission.settled;
        if (response.data.data.page > this.inviteLogs.page){
           if (response.data.data.friends!==null){
            response.data.data.friends.forEach((item) => {
              this.inviteLogs.rows.push(item)
              this.inviteLogs.total = response.data.data.total;
              this.inviteLogs.page = response.data.data.page;
              this.finished = Math.ceil(this.inviteLogs.total / rows) <= page;
              if (cb !== undefined)
                cb();
            })
           }
        }
      });
    },
    changeInvite(type){
      this.type=type;
      this.page=0;
      if (type==='second'){
        this.second_invite=true;
        this.first_invite=false;
        this.friend_type=1;
      }else{
        this.second_invite=false;
        this.first_invite=true;
        this.friend_type=0;
      }
      switch (type){
        case 'first':
          this.inviteLogs.rows = [];
          this.inviteLogs.page = 0;
          this.InviteLogs(this.page += 1, () => {
            setTimeout(() => {
              this.loading = false;
            }, 1000)
          })
          break
        default:
          this.inviteLogs.rows = [];
          this.inviteLogs.page = 0;
          this.InviteLogs(this.page += 1, () => {
            setTimeout(() => {
              this.loading = false;
            }, 1000)
          })
          break
      }
    },
    mask(){
      this.showDialog =true;
      this.timeToogle=true;
    },
    maskRemove(time_type){
      this.time_type=time_type;
      this.showDialog =false;
      this.timeToogle=false;
      if (time_type==='all_time'){
        this.begin_at="";
        this.end_at="";
        this.invite_time_select='全部时间'
      }else if(time_type==='seven'){
        let currentDate = parseInt((new Date().getTime()/1000).toString());
        let old7Date =currentDate - 86400 * 7;
        this.begin_at=old7Date;
        this.end_at=currentDate;
        this.invite_time_select='最近7天'
      }else{
        let now = new Date(); // 当前日期
        let nowYear = now.getFullYear(); //当前年
        let nowMonth = now.getMonth(); //当前月 （值为0~11）注意此处范围
        this.begin_at = (new Date(nowYear, nowMonth, 1).getTime())/1000; // 本月开始时间
        this.end_at = (new Date(nowYear, nowMonth + 1, 0).getTime())/1000; // 本月结束时间
        this.invite_time_select='本月'
      }
      this.$request.request(this.$member.token()).get('nuclear/invites',{friend_type:this.friend_type,begin_at:this.begin_at,end_at:this.end_at,page:1,size:50}).then((response) => {
        this.invite_value= response.data.data.total;
        this.invite_money_all=response.data.data.commission.total;
        this.invite_money_wait=response.data.data.commission.settled;
        this.inviteLogs.rows=[];
        response.data.data.friends.forEach((item) => {
          this.inviteLogs.rows.push(item)
        })
      });
    },
    formatDate(time) {
      let date = new Date(time * 1000);
      return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds()
    },
    onLoad() {
      if (!this.loading) {
        this.loading = !this.loading;
        switch (this.type){
          case 'first':
            this.InviteLogs.page = 0;
            this.InviteLogs(this.page++, () => {
              setTimeout(() => {
                this.loading = false;
              }, 1000)
            })
            break
          default:
            this.InviteLogs.page = 0;
            this.InviteLogs(this.page++, () => {
              setTimeout(() => {
                this.loading = false;
              }, 1000)
            })
        }
      }
    },
    mask_close(){
      this.showDialog=false;
      this.timeToogle=false;
    }
  }
}
</script>

<style scoped>

</style>