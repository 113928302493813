<template>
  <div id="withdrawal">
      <div class="withdrawal-top">
          <div class="top">
            <div class="left">
              <img src="/images/money1.png" alt="">
              <p>可提现金额</p>
            </div>
            <div class="right" v-on:click="rules()">
              <img src="/images/money2.png" alt="">
              <p>规则</p>
            </div>
          </div>
        <p class="money"><span>￥</span><span>{{all_money}}</span></p>
        <p class="limit">*最低提现金额为<span>{{min_cash_limit}}</span>元</p>
      </div>
      <div class="withdrawal-input">
        <div class="input-top">
          <p>提现金额（元）</p>
          <router-link class="withdrawal-record" to="/withdrawal_record">
            <span>提现记录></span>
          </router-link>
        </div>
        <div class="input-bottom" v-bind:class="{input_red:withdrawal_input}">
          <input type="text" v-model="withdrawal_money"  autocomplete="off"/>
          <div class="input-line"></div>
          <span v-on:click="withdrawal_all()">全部提现</span>
        </div>
      </div>
      <div class="withdrawal-methods">
        <p>提现方式</p>
        <div class="withdrawals">
          <img src="/images/alipay.png" alt="">
          <p id="withdrawals_alipay">{{withdrawals_alipay_show}}</p>
          <span v-on:click="withdrawal_Account('alipay')" v-show="withdrawals_text_alipay">点击绑定></span>
          <span v-on:click="withdrawal_judge('ALIPAY')" v-show="judge_alipay">申请提现></span>
        </div>
        <div class="withdrawals">
          <img src="/images/wechatpay.png" alt="">
          <p id="withdrawals_wechat">{{withdrawals_wechat_show}}</p>
          <span v-on:click="withdrawal_Account('wechat')" v-show="withdrawals_text_wechat">点击绑定></span>
          <span v-on:click="withdrawal_judge('WECHAT')" v-show="judge_wechat">申请提现></span>
        </div>
      </div>
    <div v-show="showWithdrawal" class="withdrawal_fail">
      <img src="/images/failed.png" alt="">
      <p>提现失败</p>
      <p>*最低提现金额为<span>{{min_cash_limit}}</span>元</p>
      <span class="go-money" v-on:click="go_money()">马上去赚钱</span>
    </div>
    <div v-show="Security" class="Security">
        <p>安全验证</p>
        <input type="text" v-model="password">
        <p v-show="password_default">为保证您的账号安全，请输入登录密码</p>
        <p v-show="password_error">账号密码输入错误</p>
        <span class="Immediately" v-on:click="Immediately()">立即兑换</span>
        <span class="close" v-on:click="close_Security()">X</span>
    </div>
    <div class="withdrawal-account" v-show="withdrawal_account">
      <p class="withdrawal-way" v-show="alipay">提现到支付宝</p>
      <p class="withdrawal-way" v-show="wechat">提现到微信</p>
      <p class="withdrawal-way-wechat" v-show="wechat">微信需在“收付款 > 向银行或手机号转载 > 手机号收款 设置”开启收款开关</p>
      <div class="account">
        <span id="account-way">个人账户</span>
        <input type="text" placeholder="绑定的账号" v-bind:class="{active_input:input_account}" v-model="account_number">
      </div>
      <div class="name">
        <span>姓名</span>
        <input type="text" v-model="account_name" v-bind:class="{active_input:input_username}">
      </div>
      <span class="binding" v-on:click="bind()">绑定</span>
    </div>
    <div class="withdrawal_rules" v-show="withdrawal_rules">
      <span v-on:click="close_rules()">X</span>
      <p>提现规则</p>
      <p>1.最低提现额度：需满100元，方可提现</p>
      <p>2.提现到账时间：提现成功后一般将在3-5个工作日到账，周末顺延。如逾期未到账，请查询提现账户的入账明细和推广个人中心余额是否有退回。</p>
      <p>3.为保障您的财产安全只能使用微信和支付宝作为提现方式，提现请注意自己的账户是否填对，因用户未填写真实、完整、无误的个人信息的，用户应自行承担因此导致的一切损失及责任，包括但不限于：提现无法到账、与他人发生账号纠纷等。</p>
      <p>4、用户若发现本软件存在漏洞、BUG等应当及时向引力科技客服反馈，如用户利用软件漏洞、BUG等谋取不正当利益，引力科技有权对相关账号采取措施，包括但不限于封停账号、追偿不当得利等。</p>
      <p>5、用户需确认，在使用引力加速器的过程中，可能会遇到不可抗力、计算机病毒或黑客攻击、用户所在位置、用户关机以及其他任何技术、互联网络、通信线路等风险因素，使产品服务中断。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱
        、政府行动等。出现上述情况时，引力科技将努力在第一时间与相关单位配合，及时进行修复，但是由此给用户或第三方造成的损失，引力不承担任何责任。</p>
      <p>6、用户理解并确认，本公司需要定期或不定期地对引力加速器软件或相关的设备进行检修或者维护，如因此类情况而造成服务在合理时间内的中断，引力科技无需为此承担任何责任。</p>
      <p>7、用户申请提现，即代表已经阅读过本提现规则</p>
      <p>8、如在提现过程中有任何疑问，请咨询客服</p>
      <p>本提现规则撰写、生效日：2021年12月01日</p>
    </div>
    <div v-show="showDialog"  class="mask">
    </div>
  </div>
</template>

<script>
import "@/styles/withdrawal.scss";
import md5 from "md5";
export default {
  name: "Withdrawal",
  data(){
    return{
      type: this.$router.currentRoute.value.query.type,
      judge_type:this.$router.currentRoute.value.query.judge_type,
      showDialog:false,
      showWithdrawal:false,
      Security:false,
      withdrawal_account:false,
      wechat:false,
      loading: false,
      withdrawals_text_alipay:true,
      withdrawals_text_wechat:true,
      withdrawals_alipay_show:"提现至支付宝",
      withdrawals_wechat_show:"提现至微信",
      account_number:"",
      account_name:"",
      alipay:true,
      all_money:'0',
      withdrawal_money:'',
      judge_alipay:false,
      judge_wechat:false,
      password:'',
      hmac:'',
      password_error:false,
      password_default:true,
      min_cash_limit:'',
      input_account:false,
      input_username:false,
      withdrawal_input:false,
      withdrawal_rules:false
    }
  },
  created(){
    this.$request.request(this.$member.token()).get('nuclear/accounts').then((response) => {
      this.value=response.data;
      if (response.data.code===0){
        response.data.data.forEach((item) =>{
          if (item.method==="WECHAT"){
            this.withdrawals_wechat_show=item.account;
            this.withdrawals_text_wechat=false;
            this.judge_wechat=true;
          }else{
            this.withdrawals_alipay_show=item.account;
            this.withdrawals_text_alipay=false;
            this.judge_alipay=true;
          }
        });
      }
    });
    this.$member.load((member) => {
      this.all_money=member.distributor.edges.wallet.amount.toFixed(2)/100;
      this.all_withdrawal=member.distributor.edges.wallet.extracted.toFixed(2)/100;
      localStorage.setItem('all_withdrawal',this.all_withdrawal);
    });
    this.$request.request().get('nuclear/settlement_setting').then((response) => {
      this.min_cash_limit = response.data.data.min_cash_limit/100;
    });
  },
  methods:{
    withdrawal_judge(judge_type){
      this.judge_type=judge_type;
      if (this.judge_type==='ALIPAY'){
        localStorage.setItem('Withdrawal_channel', '支付宝');
        localStorage.setItem('Withdrawal_money',this.withdrawal_money);
      }else {
        localStorage.setItem('Withdrawal_channel', '微信');
        localStorage.setItem('Withdrawal_money',this.withdrawal_money);
      }
      if (this.withdrawal_money===""){
        this.withdrawal_input=true;
        setTimeout( ()=>{
          this.withdrawal_input=false;
        },1000)
      }else if(this.withdrawal_money>this.all_money){
        this.withdrawal_money="余额不足"
        this.withdrawal_input=true;
        setTimeout( ()=>{
          this.withdrawal_input=false;
          this.withdrawal_money=""
        },1000)
      }
      else if (this.withdrawal_money>=this.min_cash_limit){
          this.showDialog=true;
          this.Security=true;
        }else{
          this.showDialog=true;
          this.showWithdrawal=true;
        }
    },
    go_money(){
      this.showDialog=false;
      this.showWithdrawal=false;
    },
    Immediately(){
      this.$request.request().get('login/hmac').then((response) => {
        this.hmac = response.data.recordset;
         this.password_md5=md5( this.hmac + md5('initap_' + this.password))
        this.$request.request(this.$member.token()).post('nuclear/cash_apply', {method:this.judge_type,total:Number(this.withdrawal_money*100),
          hmac:this.hmac,password:this.password_md5}).then((response) => {
          this.value = response.data.code;
          if (response.data.code===0){
            this.showDialog=false;
            this.Security=false;
            this.$router.push('/withdrawal_success');
          }else{
            this.password=''
            this.password_error=true;
            this.password_default=false;
          }
        });
      });
    },
    withdrawal_Account(type){
      this.type=type;
      this.withdrawal_account=true;
      this.showDialog=true;
      if (this.type==='wechat'){
        this.wechat=true;
        this.alipay=false;
      }else{
        this.wechat=false;
        this.alipay=true;
      }
    },
    bind(){
      if (this.type==='alipay'){
        this.method='ALIPAY';
      }else{
        this.method='WECHAT';
      }
      if (this.account_number===""){
        this.input_account=true;
        setTimeout( ()=>{
          this.input_account=false;
        },2000)
      }else if(this.account_name===""){
        this.input_username=true;
        setTimeout( ()=>{
          this.input_username=false;
        },2000)
      }
      else{
        this.$request.request(this.$member.token()).post('nuclear/accounts',{account:this.account_number,method:this.method,name:this.account_name}).then((response) => {
          this.value=response;
          this.$request.request(this.$member.token()).get('nuclear/accounts').then((response) => {
            this.value=response.data;
            if (response.data.code===0){
              response.data.data.forEach((item) =>{
                if (item.method==="WECHAT"){
                  this.withdrawals_wechat_show=item.account;
                  this.withdrawals_text_wechat=false;
                  this.judge_wechat=true;
                }else{
                  this.withdrawals_alipay_show=item.account;
                  this.withdrawals_text_alipay=false;
                  this.judge_alipay=true;
                }
              });
            }
          });
        });
        this.showDialog=false;
        this.withdrawal_account=false;
      }
    },
    withdrawal_all(){
      this.withdrawal_money=this.all_money;
    },
    close_Security(){
      this.showDialog=false;
      this.Security=false;
    },
    rules(){
      this.showDialog=true;
      this.withdrawal_rules=true;
    },
    close_rules(){
      this.showDialog=false;
      this.withdrawal_rules=false;
    }
  }

}
</script>

<style scoped>

</style>