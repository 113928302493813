<template>
  <div id="order">
    <div class="order-nav">
      <span v-bind:class="{active: type === 'all'}" v-on:click="changeType('all')">全部</span>
      <span v-bind:class="{active: type === 'wait-payment'}" v-on:click="changeType('wait-payment')">待付款</span>
      <span v-bind:class="{active: type === 'wait-settlement'}" v-on:click="changeType('wait-settlement')">待结算</span>
      <span v-bind:class="{active: type === 'have-commission' }" v-on:click="changeType('have-commission')">已返佣</span>
      <span v-bind:class="{active: type === 'refund'}" v-on:click="changeType('refund')">已退款</span>
    </div>
    <div class="order-message">
      <div class="order-message-p">
        <p>共<span>{{ order_total }}</span>笔订单</p>
        <p v-show="all_order_money">获得佣金<span>{{ order_money }}</span>元</p>
      </div>
      <div class="order-message-time">
        <span v-on:click="mask()">{{ time_select }}</span>
        <div class="timeToogle" v-show="timeToogle">
          <span v-on:click="maskRemove('all_time')">全部时间</span>
          <span v-on:click="maskRemove('seven')">最近7天</span>
          <span v-on:click="maskRemove('during_month')">本月</span>
        </div>
      </div>
    </div>
    <van-list loading-text="加载中..." v-model="loading" offset="50" :finished="finished" finished-text="没有更多了"
              @load="onLoad">
      <div class="order-table" v-for="(item) in orderLogs.rows" v-bind:key="item.id">
        <div class="order-table-status">
          <p class="time">下单时间：<span>{{ formatDate(item.updated_at) }}</span></p>
          <p class="status" v-if="item.status===0">待付款</p>
          <p class="status commission" v-else-if="item.status===4">已返佣</p>
          <p class="status refund" v-else-if="item.status===1">已退款</p>
          <p class="status settlement" v-else-if="item.status===2||item.status===3">待结算</p>
        </div>
        <div class="order-table-info">
          <div class="order-table-info-left">
            <p>买家：<span>{{ item.edges.customer.nickname }}</span></p>
            <p>{{ item.edges.goods.name }}</p>
          </div>
          <div class="order-table-info-right">
            <p>订单价格：<span>￥{{ item.amount.toFixed(2) / 100 }}</span></p>
          </div>
        </div>
        <div class="order-table-money">
          <p v-if="item.status===4||item.status===3">商品佣金：￥<span>{{ item.commission.toFixed(2) / 100 }}</span></p>
          <p v-else-if="item.status===0">商品佣金：<span>付款后计算</span></p>
          <p v-else-if="item.status===1">无法获得佣金</p>
        </div>
      </div>
    </van-list>
    <div v-show="showDialog" class="mask" v-on:click="mask_close()">

    </div>
  </div>
</template>

<script>
import "@/styles/order.scss";

export default {
  name: "Order",
  data() {
    return {
      type: this.$router.currentRoute.value.query.type || 'all',
      time_type: this.$router.currentRoute.value.query.type || 'all_time',
      loading: false,
      finished: false,
      showDialog: false,
      timeToogle: false,
      page: 0,//页数
      orderLogs: {rows: [], page: 0},
      order_total: '',
      order_money: '',
      time_select: '全部时间',
      all_order_money:true
    }
  },
  created() {
  },
  methods: {
    changeType(type) {
      this.page = 0;
      this.type = type;
      if (this.type === 'wait-payment') {
        this.status = 0;
        this.all_order_money=false;
      } else if (this.type === 'wait-settlement') {
        this.status = '2,3';
        this.all_order_money=false;
      } else if (this.type === 'have-commission') {
        this.status = 4;
        this.all_order_money=false;
      } else if (this.type === 'refund') {
        this.status = 1;
        this.all_order_money=false;
      } else {
        this.status = "";
        this.all_order_money=true;
      }
          this.orderLogs.page = 0;
          this.orderLogs.rows = [];
          this.loading= false;
      this.onLoad()
    },
    LoadOrderlogs(page, cb) {
      page = page === undefined ? 1 : page;
      let rows = 6;
      this.$request.request(this.$member.token()).get('nuclear/orders', {
        status: this.status,
        page: page,
        size: rows,
      }).then((response) => {
        this.order_total = response.data.data.total;
        this.order_money = response.data.data.commission.toFixed(2) / 100;
        if (response.data.data.page > this.orderLogs.page) {
          response.data.data.orders.forEach((item) => {
            this.orderLogs.rows.push(item)
          })
          this.orderLogs.total = response.data.data.total;
          this.orderLogs.page = response.data.data.page;
          this.finished = Math.ceil(this.orderLogs.total / rows) <= page;
          if (cb !== undefined)
            cb();
        }
      });
    },
    mask() {
      this.showDialog = true;
      this.timeToogle = true;
    },
    maskRemove(time_type) {
      this.time_type = time_type;
      this.showDialog = false;
      this.timeToogle = false;
      if (time_type === 'all_time') {
        this.begin_at = "";
        this.end_at = "";
        this.time_select = '全部时间'
      } else if (time_type === 'seven') {
        let currentDate = parseInt((new Date().getTime() / 1000).toString());
        let old7Date = currentDate - 86400 * 7;
        this.begin_at = old7Date;
        this.end_at = currentDate;
        this.time_select = '最近7天'
      } else {
        let now = new Date(); // 当前日期
        let nowYear = now.getFullYear(); //当前年
        let nowMonth = now.getMonth(); //当前月 （值为0~11）注意此处范围
        this.begin_at = (new Date(nowYear, nowMonth, 1).getTime()) / 1000; // 本月开始时间
        this.end_at = (new Date(nowYear, nowMonth + 1, 0).getTime()) / 1000; // 本月结束时间
        this.time_select = '本月'
      }
      this.$request.request(this.$member.token()).get('nuclear/orders', {
        status: this.status,
        begin_at: this.begin_at,
        end_at: this.end_at,
        page:this.page,
        size:50
      }).then((response) => {
        this.order_total = response.data.data.total;
        this.order_money = response.data.data.commission.toFixed(2) / 100;
        this.orderLogs.rows = [];
        response.data.data.orders.forEach((item) => {
          this.orderLogs.rows.push(item)
        })
      });
    },
    onLoad() {
      if (!this.loading) {
        this.loading = !this.loading;
        switch (this.type) {
          case 'all':
            this.LoadOrderlogs(++this.page, () => {
              setTimeout(() => {
                this.loading = false;
              }, 1000)
            })
            break;
          case 'wait-payment':
            this.LoadOrderlogs(++this.page, () => {
              setTimeout(() => {
                this.loading = false;
              }, 1000)
            })
            break;
          case 'wait-settlement':
            this.LoadOrderlogs(this.page += 1, () => {
              setTimeout(() => {
                this.loading = false;
              }, 1000)
            })
            break;
          case 'have-commission':
            this.LoadOrderlogs(this.page += 1, () => {
              setTimeout(() => {
                this.loading = false;
              }, 1000)
            })
            break;
          case 'refund':
            this.LoadOrderlogs(this.page += 1, () => {
              setTimeout(() => {
                this.loading = false;
              }, 1000)
            })
            break;
        }
      }

    },
    formatDate(time) {
      let date = new Date(time * 1000);
      return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds()
    },
    mask_close(){
      this.showDialog=false;
      this.timeToogle=false;
    }
  }
}
</script>

<style scoped>

</style>