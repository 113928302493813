export default function Base64() {
    function image2Base64(img) {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, img.width, img.height);
        var dataURL = canvas.toDataURL("image/png");
        return dataURL;
    }

    this.do=(src)=>{
        var base64 = "";
        var img = new Image();
        img.src =src;
        img.onload = () => {
            base64 = image2Base64(img);
            return base64;
        }
    }

    this.newDo = (src,fun) => {
        var string = "";
        var img = new Image();
        img.src = src;
        img.onload = () => {
            string = image2Base64(img);
            fun(string);
        }
    }
}
