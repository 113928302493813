export default {
    index: {
        meta: {
            title: "推广中心"
        },

    },
    // form: {
    //     meta: {
    //         title: "问卷调查"
    //     },
    //
    // },
    invite: {
        meta: {
            title: "邀请好友"
        },
    },
    invites: {
        meta: {
            title: "我的邀请"
        },
    },
    order: {
        meta: {
            title: "推广订单"
        },
    },
    home: {
        header: {
            activity_rule: '推广须知'
        }
    },
    withdrawal: {
        meta: {
            title: "结算中心"
        },
    },
    withdrawal_record: {
        meta: {
            title: "提现记录"
        },
    },
    withdrawal_success: {
        meta: {
            title: "申请成功"
        },
    },
    list: {
        meta: {
            title: "排行榜"
        },
    },
    grade_center: {
        meta: {
            title: "等级中心"
        },
    },
    // dialog: {
    //     meta: {
    //         title: "弹出层"
    //     },
    // }s
}