import {createApp} from 'vue'
import App from './App.vue'
import {createI18n} from 'vue-i18n'
import Vant from 'vant';
import 'vant/lib/index.css';
import router from './router'
import languages from './languages/languages'
import request from "./request/request";
import Member from "./member/member";

//request.setAPI('http://192.168.50.93:8090/api/');
request.setAPI('https://api.initap.com/api/');
// import * as Sentry from "@sentry/browser";
// import { Integrations } from "@sentry/tracing";
//localStorage.setItem('user-token','eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJJRCI6IjgwMDAxMCIsIkRhdGEiOiJ7XCJkZXZpY2VfaWRcIjpcImQ4YzNhNjhiLWI4ZWUtNGEyZS04OGVjLWEzZjE1ZGNiYjE0ZVwiLFwicGxhdGZvcm1cIjpcInBjXCIsXCJsYXN0X2xvZ2luX2F0XCI6MTY0MDA2NjkyNCxcInJlcXVlc3RfaWRcIjpcImZhNjgwZmIyLThkODMtMDA5ZC1mODQ5LTIxZTFiNzNiYWNmMFwifSIsImV4cCI6MTY1NTYxODkyNH0.9L9-uV9vSw77PWeyqr1CJ79cOxJ3oyBkHLfq_AdYYbc')
// Sentry.init({
//     dsn: "https://b4a3983d06054eb6aa0c25cbd491f2fd@o943425.ingest.sentry.io/5892292",
//     integrations: [new Integrations.BrowserTracing()],
//
//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
// });

const i18n = createI18n(languages);
i18n.global.locale = navigator.language;

const app = createApp(App).use(i18n).use(Vant);

app.config.globalProperties.$member = new Member();
app.config.globalProperties.$request = request;

// app.config.globalProperties.title = '';
router.beforeEach((to, from, next) => {
    if (Object.prototype.hasOwnProperty.call(to.query, 'token')) {
        app.config.globalProperties.$member.setToken(to.query.token);
    }
    app.config.globalProperties.$member.load();
    window.document.title = to.meta.title === undefined ? '默认标题' : i18n.global.t(to.meta.title);
    document.getElementById('main-title').innerText = to.meta['title'] === undefined ? '默认标题' : i18n.global.t(to.meta.title);
    if (to.path !== '/' && to.path !== "/invites" && to.path!=="/withdrawal") {
        document.getElementById('activity-rule').style.display = 'none';
    } else {
        if (to.path === '/invites') {
            document.getElementById('activity-rule').innerText  = "";
        } else {
            document.getElementById('activity-rule').style.display = 'block';
        }
        if (to.path==='/withdrawal'){
            document.getElementById('activity-rule').innerText  = "联系客服";
        }else{
            document.getElementById('activity-rule').style.display = 'block';
        }
        if (to.path==='/'){
            document.getElementById('activity-rule').innerText  = "推广须知";
        }else{
            document.getElementById('activity-rule').style.display = 'block';
        }
    }
    //
    // if (to.path === '/order/info') {
    //     document.getElementById('main-title').style.display = 'none';
    // } else {
    //     document.getElementById('main-title').style.display = 'block';
    // }

    next();
});

// app.config.globalProperties.title = 'index.header.title';
app.use(router).mount('#app');