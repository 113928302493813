import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
// import Detail from "../views/Detail";
// import PointsLog from "../views/PointsLog";
// import OrderInfo from "../views/OrderInfo";
// import Form from "../views/Form";
import Invite from "../views/Invite";
import Order from "@/views/Order";
import Invites from "@/views/Invites";
import Withdrawal from "@/views/Withdrawal";
import Withdrawal_record from "@/views/Withdrawal_record";
import List from "@/views/List";
import Grade_center from "@/views/Grade_center";
import Withdrawal_success from "@/views/Withdrawal_success";
// import Dialog from "@/views/alert";
const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: 'index.meta.title'
    },
    component: Home
  },
  // {
  //   path: '/detail',
  //   name: 'Detail',
  //   meta: {
  //     title: 'detail.meta.title'
  //   },
  //   component: Detail
  // },
  // {
  //   path: '/logs',
  //   name: 'PointsLog',
  //   meta: {
  //     title: 'points.meta.title'
  //   },
  //   component: PointsLog
  // },
  {
    path: '/withdrawal',
    name: 'Withdrawal',
    meta: {
      title: 'withdrawal.meta.title'
    },
    component: Withdrawal
  },
  // {
  //   path: '/dialog',
  //   name: 'Dialog',
  //   meta: {
  //     title: 'dialog.meta.title'
  //   },
  //   component: Dialog
  // },
  {
    path: '/withdrawal_record',
    name: 'Withdrawal_record',
    meta: {
      title: 'withdrawal_record.meta.title'
    },
    component: Withdrawal_record
  },
  // {
  //   path: '/order/info',
  //   name: 'OrderInfo',
  //   meta: {
  //     title: 'order_info.meta.title'
  //   },
  //   component: OrderInfo
  // },
  // {
  //   path: '/form',
  //   name: 'Form',
  //   meta: {
  //     title: 'form.meta.title'
  //   },
  //   component: Form
  // },
  {
    path: '/order',
    name: 'Order',
    meta: {
      title: 'order.meta.title'
    },
    component: Order
  },
  {
    path: '/invites',
    name: 'Invites',
    meta: {
      title: 'invites.meta.title'
    },
    component: Invites
  },
  {
    path: '/list',
    name: 'List',
    meta: {
      title: 'list.meta.title'
    },
    component: List
  },
  {
    path: '/grade_center',
    name: 'Grade_center',
    meta: {
      title: 'grade_center.meta.title'
    },
    component: Grade_center
  },
  {
    path: '/withdrawal_success',
    name: 'Withdrawal_success',
    meta: {
      title: 'withdrawal_success.meta.title'
    },
    component: Withdrawal_success
  },
  {
    path: '/invite',
    name: 'Invite',
    meta: {
      title: 'invite.meta.title'
    },
    component: Invite
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      title: '关于我们'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
